import Helmet from "react-helmet"
import React from "react"
import Disclosure from "@tightrope/disclaimers";
import ExtendedHomepage from "src/components/extendedHomepage"
import HomepageLayout from "src/layouts/homepage"
import {isIeEdge,isChrome,isFirefox, isSafari} from "@tightrope/lpscripts/browserdetect"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import footerStyles from 'src/styles/shared/footer.module.scss';


const homepageData = import("./homepage.json");

export default function HomePage() {
  let disclosure = null;

  if(isIeEdge()){
      disclosure = <Disclosure language='en-mbc-edge'></Disclosure>;
  }
 if(isFirefox()){
      disclosure = <Disclosure language='en-firefox'></Disclosure>;

  }
 if(isSafari()) {
     disclosure = <Disclosure language='en-safari'></Disclosure>;
 }
  if(isChrome()){
        disclosure = <Disclosure language='en-chrome'></Disclosure>;
    }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`
#disclaimers-module--disclaimers p {
  text-align: center;
  color: #777;
  font-size: 13px;
  width: 380px;
  line-height: 150%;
  margin: 0 auto 12px;
}

#disclaimers-module--disclaimers p a {
  color: #0080e6;
}

button {
width: 380px;
}
        `}</style>
      <title>Paperboy - News Delivered To You</title></Helmet>
      <ExtendedHomepage data={homepageData}>
      <div className="disc">{disclosure}</div>
      </ExtendedHomepage>
      <RawFooter language='en'></RawFooter>
    </HomepageLayout>
  )
}
